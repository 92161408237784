export default {
    language:'English',
    home: {
      image:'homeEn.png',
      name: 'ECG Smartwear',
    },
    brain:{
      image:'10En.png',
      name:'EEG Devices'
    },
    index:{
      name:'Home',
      banner:{
        title:'Healthcare · HuiChuang Health',
        des:{
          one:'AI Brain Health Evaluation',
          two:'AI-Powered ECG Smart Shirt',
          three:'AI-powered handheld ultrasound'
        }
      },
      firm:{
        firmImage:'7En.png',
        title:'About the Company',
        des:'皓世天辉控股集团是一家专注于人工智能健康医疗领域的高科技公司，致力于成为全球AI健康医疗领域的领跑者。集团旗下拥有四家子公司，分别是杭州精康科技有限公司、杭州护脉科技有限公司、杭州医策双合科技合伙企业和杭州智鸿博瑞有限公司。',
        vision_des:{
          one:{
            title:'公司愿景与使命',
            des:'皓世天辉控股集团秉承“科技引领健康未来”的理念，不断推动人工智能技术在医疗健康领域的创新与应用，为全球用户提供精准、高效的健康管理和疾病预防解决方案。',
           
          },
          two:{
            title:'技术研发与创新',
            des:'皓世天辉控股集团在核心AI技术研发和综合人工智能医疗设备制造方面拥有强大的技术实力和创新能力。公司注重与国内外顶尖科研机构和医疗机构的合作，持续推动技术进步和产品创新。',
            
          },
          three:{
            title:'企业文化',
            des:`集团倡导“以人为本、科技创新、合作共赢”的企业文化，致力于为员工提供良好的职业发展平台，与合作伙伴共同成长，共同推动全球健康医疗事业的进步。`,
            des2:'皓世天辉控股集团将继续以卓越的技术和优质的服务，努力成为全球AI健康医疗领域的标杆企业，为人类健康事业做出更大的贡献。'
          }
        },
      },
      product:{
        title:'Product Introduction',
        btn:'Learn More>',
        Dynamic_EEG:{
          image:'4En.png',
          title:'动态脑电检测分析工作站',
          des:'动态脑电图仪面向“健康中国”和“脑科学与类脑研究”国家战略需求，在脑医学与健康工程方向开展变革性诊疗理论和方法研究，突破了心理、生理信息“感”+“知”+“疗”关键技术，我们研发出“三导脑电”精神状态量化评估技术，利用智能诊断系统、深度神经网络算法，基于三导联脑电头戴采集静息状态以及使用不同的声音范式刺激下的前额脑电信号进行深度分析，对于抑郁症、焦虑症、成瘾性等疾病的检测准确率达到90%以上......',
        },
        channels_EEG:{
          image:'3En.png',
          title:'三导脑电心理健康分析测评系统',
          des:'三导脑电心理健康分析测评系统面向“健康中国”和“脑科学与类脑研究”国家战略需求，在脑科学与健康工程方向开展变革性的理论和方法研究，突破了心理、生理信息“感”+“知”+“疗”关键技术，我们研发出“三导脑电”精神状态量化评估技术，利用人工智能测评系统、深度神经网络算法，基于三导联脑电头戴采集静息状态以及使用不同的声音范式刺激下的前额脑电信号进行深度分析，对于抑郁、焦虑等心理健康障碍测评准确率达到90%以上，解决了传统心理量表测评方式存在的有效指标稀缺、主观性强、精确度低、普适化弱等问题。'
        },
        color_ultrasound:{
          image:'5En.png',
          title:'推车彩超',
          des:'智能超声诊断仪产品整体解决方案包含甲状腺超声智能诊断系统、乳腺超声智能诊断系统等产品，利用深度神经网络算法,基于多普勒超声仪动态影像数据分析，从而实现乳腺、甲状腺等身体部位健康风险评估。 通过人工智能加持，诊断服务更加便捷，设备操作更加简单，极大减少了诊断报告等待时间。'
        },
        channelstow:{
          image:'306A6455En.png'
        }

      },
      cooperation:{
        title:'PRODUCT INTRODUCTION',
      },
      institution:{
        title:'Trusted by Medical Institutions Worldwide —',
        image:'10En.png',
        num:'100+',
        schoolList:{
          one:{
            a:'复旦大学附属肿瘤医院',
            b:'浙江大学医学院附属邵逸夫医院',
            c:'上海市第六人民医院',
            d:'广州市第一人民医院',
            e:'长沙医学院附属医院',
            f:'杭州市临安第一人民医院',
            g:'南方医科大学第三医院',
            h:'广州市番禺区中医院',
          },
          two:{
            a:'湖南省肿瘤医院',
            b:'湖南省人民医院',
            c:'南华大学附属第一医院',
            d:'湖南中医大学附属二医院',
            e:'中南大学湘雅二医院',
            f:'中南大学湘雅三医院',
            g:'宁德市闽东医院',
            h:'长沙市中心医院',
          },
          three:{
            a:'中南大学湘雅医院',
            b:'广州医科大学附属第一医院',
            c:'兰州大学第二医院',
            d:'长沙县妇幼保健院',
            e:'长沙市第八医院',
            f:'云南龙陵第一人民医院',
            g:'广州市番禺区健康管理中心',
            h:'长沙市望城区人民医院',
          },
        }
      },
      plan:{
        title:'Collaborative Success -Future Roadmap',
        image:'9090484En.png'
      }
    },
    ultrasonic:{
      image:'ultraEn.png',
      name:'Ultrasound Systems'
    },
    tabs: {
      work: 'Work',
      private: 'Private',
      collect: 'Collect',
      like: 'Like',
    },
  }
  