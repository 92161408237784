<template>
  <nav>
    <div class="nav_logo">
      <img src="./assets/logos.png" />
    </div>
    <div class="nav_link">
      <router-link to="/">{{ $t("index.name") }}</router-link>
      <router-link to="/brain">{{ $t("brain.name") }}</router-link>
      <router-link to="/home">{{ $t("home.name") }}</router-link>
      <router-link to="/ultrasonic">{{ $t("ultrasonic.name") }}</router-link>
    </div>
    <div>
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          {{ $t("language") }}
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              @click="changeLang(item.id)"
              v-for="(item, index) in language"
              :key="index"
              >{{ item.value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </nav>
  <router-view />
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { locale } = useI18n();
sessionStorage.setItem('localeLang','zhCn')
// 定义方法
const changeLang = (target: string): void => {
  console.log(`Link roter triggered for: ${target}`);
  sessionStorage.setItem('localeLang',target)
  locale.value = target;
};
const language = ref([
  { value: "中文", id: "zhCn" },
  { value: "English", id: "en" },
]);
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1c1c1c;
  width: window-width;
  min-width: 1200px;
}
nav {
  height: 80px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  z-index: 1000;
  width: calc((100% - 46%));
  padding: 0 23%;
  font-size: 18px;
  align-items: center;
  background: #fefefe;
  // .nav_logo {
  //   padding-left: 23%;
  // }
  .nav_link {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    color: #3d3d3d;
    text-decoration: none;
    // margin-left: 80px;
    &.router-link-exact-active {
      color: #010101;
      font-weight: 700;
    }
    &:last-child {
      padding-right: 100px;
    }
  }
}
</style>
