import { createRouter, createWebHashHistory , createWebHistory,RouteRecordRaw } from 'vue-router'
import indexView from '../views/indexView.vue'

const routes: Array<RouteRecordRaw> = [
  
  {
    path: '/',
    name: 'indexView',
    component: indexView
  },
  {
    path: '/home',
    name: 'homeView',
    component: () => import(/* webpackChunkName: "about" */ '../views/HomeView.vue')
  },
  {
    path: '/brain',
    name: 'brainView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/brainView.vue')
  },
  {
    path: '/ultrasonic',
    name: 'ultrasonicView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ultrasonicView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
