<template>
  <div class="index">
    <div class="banner">
      <div class="title" :style="banner.title">
        <span>AI</span>
        {{ $t("index.banner.title") }}
      </div>
      <div class="des">
        <span
          class="item"
          :style="banner.des"
          v-for="item in ['one', 'two', 'three']"
          :key="item"
        >
          {{ $t(`index.banner.des.${item}`) }}
        </span>
      </div>
    </div>
    <div class="firm">
      <div class="title">
        {{ $t("index.firm.title") }}
        <div
          style="
            width: 113px;
            height: 7px;
            background: #3663d0;
            border-radius: 174px 174px 174px 174px;
            margin-top: 12px;
          "
        ></div>
      </div>
      <div class="des">
        <img :src="firmImage" alt="" />
        <!-- <div class="vo_des">
          {{ $t("index.firm.des") }}
        </div>
        <div class="visi">
          <div class="vision" v-for="ite in ['one', 'two']" :key="ite">
            <div class="vision_title">
              {{ $t(`index.firm.vision_des.${ite}.title`) }}
            </div>
            <div class="vision_des">
              {{ $t(`index.firm.vision_des.${ite}.des`) }}<br />
            </div>
          </div>
        </div> -->
      </div>
      <!-- <div class="vision_three">
        <div class="vision_title">
          {{ $t(`index.firm.vision_des.three.title`) }}
        </div>
        <div class="vision_des">
          {{ $t(`index.firm.vision_des.three.des`) }}<br />
        </div>
        <div class="vision_des">
          {{ $t(`index.firm.vision_des.three.des2`) }}<br />
        </div>
      </div> -->
    </div>
    <div class="product">
      <div class="title">
        {{ $t("index.product.title") }}
        <div
          style="
            width: 113px;
            height: 7px;
            background: #3663d0;
            border-radius: 174px 174px 174px 174px;
            margin-top: 12px;
          "
        ></div>
      </div>
      <div class="dynamic">
        <div class="content" :style="dynamicContentStyle">
          <!-- <div class="title">
            {{ $t("index.product.Dynamic_EEG.title") }}
          </div>
          <div class="des">
            {{ $t("index.product.Dynamic_EEG.des") }}
          </div> -->
          <div>
            <div class="btn" @click="linkRoter('brain')">
              {{ $t("index.product.btn") }}
            </div>
          </div>
        </div>
      </div>
      <div class="channels">
        <div class="content" :style="channelsContentStyle">
          <!-- <div class="title">
            {{ $t("index.product.channels_EEG.title") }}
          </div>
          <div class="des">
            {{ $t("index.product.channels_EEG.des") }}
          </div> -->
          <div class="btn" @click="linkRoter('brain')">
            {{ $t("index.product.btn") }}
          </div>
        </div>
        <!-- <img src="../assets/index/3.png" /> -->
      </div>
      <div class="color_ultrasound">
        <!-- <img src="../assets/index/5.png" /> -->
        <div class="content" :style="color_ultrasoundContentStyle">
          <!-- <div class="title">
            {{ $t("index.product.color_ultrasound.title") }}
          </div> -->
          <!-- <div class="des">
            {{ $t("index.product.color_ultrasound.des") }}
          </div> -->
          <div class="btn" @click="linkRoter('home')">
            {{ $t("index.product.btn") }}
          </div>
        </div>
      </div>
      <div class="channelstow">
        <div class="content" :style="channelstowStyle">
          <!-- <div class="title">
            {{ $t("index.product.channels_EEG.title") }}
          </div>
          <div class="des">
            {{ $t("index.product.channels_EEG.des") }}
          </div> -->
          <div class="btn" @click="linkRoter('ultrasonic')">
            {{ $t("index.product.btn") }}
          </div>
        </div>
        <!-- <img src="../assets/index/306A6455.png" /> -->
      </div>
    </div>
    <div class="cooperation">
      <div class="title">
        {{ $t("index.cooperation.title") }}
      </div>
      <img src="../assets/index/34783659673.png" alt="" />
    </div>
    <div class="institution">
      <div class="title">
        {{ $t("index.institution.title") }}
        <span style="font-size: 52px; font-weight: 700">{{
          $t("index.institution.num")
        }}</span>
      </div>
      <div class="content">
        <img :src="institutionImage" alt="" />
        <!-- <div class="school" v-for="(item, index) in schoolList" :key="index">
          <div class="school_des" v-for="des in item" :key="des">
            {{ des }}
          </div>
        </div> -->
      </div>
    </div>
    <div class="plan">
      <div class="title">
        {{ $t("index.plan.title") }}
        <!-- <span style="font-size: 52px; font-weight: 700">{{
          $t("index.institution.num")
        }}</span> -->
      </div>
      <img :src="planImage" alt="" />
    </div>
    <div class="footer"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "indexView",
  data() {
    return {
      schoolList: [
        [
          this.$t("index.institution.schoolList.one.a"),
          this.$t("index.institution.schoolList.one.b"),
          this.$t("index.institution.schoolList.one.c"),
          this.$t("index.institution.schoolList.one.d"),
          this.$t("index.institution.schoolList.one.e"),
          this.$t("index.institution.schoolList.one.f"),
          this.$t("index.institution.schoolList.one.g"),
          this.$t("index.institution.schoolList.one.h"),
        ],
        [
          this.$t("index.institution.schoolList.two.a"),
          this.$t("index.institution.schoolList.two.b"),
          this.$t("index.institution.schoolList.two.c"),
          this.$t("index.institution.schoolList.two.d"),
          this.$t("index.institution.schoolList.two.e"),
          this.$t("index.institution.schoolList.two.f"),
          this.$t("index.institution.schoolList.two.g"),
          this.$t("index.institution.schoolList.two.h"),
        ],
        [
          this.$t("index.institution.schoolList.three.a"),
          this.$t("index.institution.schoolList.three.b"),
          this.$t("index.institution.schoolList.three.c"),
          this.$t("index.institution.schoolList.three.d"),
          this.$t("index.institution.schoolList.three.e"),
          this.$t("index.institution.schoolList.three.f"),
          this.$t("index.institution.schoolList.three.g"),
          this.$t("index.institution.schoolList.three.h"),
        ],
      ],
      banner: {
        title: { "font-Size": "80px" },
        des: { "font-Size": "18px" },
      },
      dynamicContentImage: {},
      firmImage: require("@/assets/index/" + this.$t("index.firm.firmImage")),
      institutionImage: require("@/assets/index/" +
        this.$t("index.institution.image")),
      planImage: require("@/assets/index/" + this.$t("index.plan.image")),
      dynamicContentStyle: {
        background: `url(${require("@/assets/index/" +
          this.$t("index.product.Dynamic_EEG.image"))})  no-repeat`,
        height: "445px",
      },
      channelsContentStyle: {
        background: `url(${require("@/assets/index/" +
          this.$t("index.product.channels_EEG.image"))})  no-repeat`,
        height: "445px",
      },
      color_ultrasoundContentStyle: {
        background: `url(${require("@/assets/index/" +
          this.$t("index.product.color_ultrasound.image"))}) no-repeat`,
        height: "445px",
      },
      channelstowStyle: {
        background: `url(${require("@/assets/index/" +
          this.$t("index.product.channelstow.image"))}) no-repeat`,
        height: "445px",
      },
    };
  },
  mounted() {
    if (this.$i18n.locale == "en") {
      this.banner.title = { "font-Size": "60px" };
      this.banner.des = { "font-Size": "14px" };
      this.banner.title = { "font-Size": "60px" };
      this.banner.des = { "font-Size": "14px" };
      this.dynamicContentStyle.height = "526px";
      this.channelsContentStyle.height = "592px";
      this.channelstowStyle.height = "469px";
      this.color_ultrasoundContentStyle.height = "491px";
    } else {
      this.banner.title = { "font-Size": "80px" };
      this.banner.des = { "font-Size": "16px" };
      this.banner.title = { "font-Size": "80px" };
      this.banner.des = { "font-Size": "16px" };
      this.dynamicContentStyle.height = "445px";
      this.channelsContentStyle.height = "445px";
      this.channelstowStyle.height = "445px";
      this.color_ultrasoundContentStyle.height = "445px";
    }
  },
  watch: {
    // 监听语言变化更新图片
    "$i18n.locale"() {
      this.firmImage = require(`@/assets/index/${this.$t(
        "index.firm.firmImage"
      )}`);
      this.institutionImage = require(`@/assets/index/${this.$t(
        "index.institution.image"
      )}`);
      this.planImage = require(`@/assets/index/${this.$t("index.plan.image")}`);
      this.dynamicContentStyle.background = `url(${require("@/assets/index/" +
        this.$t("index.product.Dynamic_EEG.image"))}) no-repeat`;

      this.channelsContentStyle.background = `url(${require("@/assets/index/" +
        this.$t("index.product.channels_EEG.image"))}) no-repeat`;
      this.color_ultrasoundContentStyle.background = `url(${require("@/assets/index/" +
        this.$t("index.product.color_ultrasound.image"))}) no-repeat`;
      this.channelstowStyle.background = `url(${require("@/assets/index/" +
        this.$t("index.product.channelstow.image"))}) no-repeat`;
      console.log(this.$i18n.locale, "font-Size");
      if (this.$i18n.locale == "en") {
        this.banner.title = { "font-Size": "60px" };
        this.banner.des = { "font-Size": "14px" };
        this.dynamicContentStyle.height = "526px";
        this.channelsContentStyle.height = "592px";
        this.channelstowStyle.height = "469px";
        this.color_ultrasoundContentStyle.height = "491px";
      } else {
        this.banner.title = { "font-Size": "80px" };
        this.banner.des = { "font-Size": "16px" };
        this.dynamicContentStyle.height = "445px";
        this.channelsContentStyle.height = "445px";
        this.channelstowStyle.height = "445px";
        this.color_ultrasoundContentStyle.height = "445px";
      }
    },
  },
  setup() {
    const router = useRouter(); // 使用 useRouter 获取路由实例

    // 定义方法
    const linkRoter = (target: string): void => {
      console.log(`Link roter triggered for: ${target}`);
      router.push({ name: target }); // 使用 router.push 替代 this.$router.push
    };

    return {
      linkRoter,
    };
  },
});
</script>
<style lang="less" scoped>
@import url(../styles/indexView.less);
</style>